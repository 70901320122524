import {Workbox, messageSW} from 'workbox-window';
import {WorkboxLifecycleEvent} from 'workbox-window/utils/WorkboxEvent';

if ('serviceWorker' in navigator) {
    window.onload = async () => {
        let registration: ServiceWorkerRegistration|undefined;
        const wb = new Workbox('sw.js');
        const showSkipWaitingPrompt = (event: WorkboxLifecycleEvent) => {
            if (confirm('There is a new version of this software available, please upgrade now by clicking OK.') == true) {
                setTimeout(() => {
                    wb.addEventListener('controlling', (event: WorkboxLifecycleEvent) => {
                        window.location.reload();
                    });

                    if (registration && registration.waiting) {
                        messageSW(registration.waiting, {type: 'SKIP_WAITING'});
                    }
                }, 0);
            }
        }

        wb.addEventListener('waiting', showSkipWaitingPrompt);
        //wb.addEventListener('externalwaiting', showSkipWaitingPrompt);
        registration = await wb.register();
    }
}

